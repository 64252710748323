<template></template>

<script setup>
    import {shoppingCartStore} from './Store.ts';
    import {onMounted} from 'vue';

    const props = defineProps({
        products: Array,
    });
    const store = shoppingCartStore();

    onMounted(() => {
        console.log('AddProductsToStore');

        props.products.forEach((product) => {
            let item = store.filterProductFields(product);
            store.addItem(item, product.orderQuantity, true);
        });
    });
</script>
